<template>
  <div class="component-wrap">
    <div class="component-tit">
      {{ $t("mine.my_likes") }}
      <!-- 我的点赞 -->
    </div>
    <div class="filter-wrap" id="pageTop">
      <!-- 类型 -->
      <ListFilter
        only
        :name="$t('XB_Type')"
        :allObj="false"
        :dataSource="typesOptions"
        @onChange="switchTypes"
      >
        <template v-slot:right>
          <div class="search">
            <a-input-search
              v-model:value="name"
              :placeholder="$t('LB_Coach_EnterName')"
              style="width: 220px"
              enter-button
              allow-clear
              @search="onSearch"
            />
            <!-- 请输入名称 -->
          </div>
        </template>
      </ListFilter>
    </div>
    <a-spin :spinning="listLoading">
      <div class="content-wrap">
        <template v-if="dataList.length">
          <div class="content card">
            <div class="item" v-for="(item, index) in dataList" :key="index">
              <div class="data-card card" @click="jump(item)">
                <div
                  class="cover"
                  :style="{
                    backgroundImage: 'url(' + coverImg + ')',
                    backgroundSize: 'contain',
                  }"
                >
                  <img
                    :src="
                      item.resourceImg ||
                      require(`@/assets/image/mine/collect_cover.png`)
                    "
                    :style="{
                      maxWidth: '100%',
                      width: 'auto',
                      background: '#fff',
                    }"
                    alt="cover"
                  />
                </div>
                <div class="detail">
                  <h5>
                    <a-popover trigger="hover">
                      <template #content>
                        <div class="popover-content">
                          {{ item.resourceName }}
                        </div>
                      </template>
                      {{ item.resourceName }}
                    </a-popover>
                  </h5>
                  <div class="row mt12" v-if="item.folderName">
                    {{ $t("like.news_category") }}：{{ item.folderName }}
                    <!-- 新闻分类 -->
                  </div>
                  <div class="row mt12">
                    {{ $t("like.like_time") }}：{{
                      dateFormat(item.createTime)
                    }}
                    <!-- 点赞时间 -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="page-Wrap">
            <a-pagination
              show-quick-jumper
              hideOnSinglePage
              :defaultPageSize="15"
              v-model:current="currentPage"
              :total="pageTotal"
              @change="pageChange"
            >
              <template #itemRender="{ type, originalElement }">
                <a class="page-a" v-if="type === 'prev'">{{ $t("cm_pre") }}</a>
                <a class="page-a" v-else-if="type === 'next'">{{
                  $t("cm_next")
                }}</a>
                <renderVNode v-else :vnode="originalElement"></renderVNode>
              </template>
            </a-pagination>
          </div>
        </template>
        <a-empty
          v-if="!dataList.length && !listLoading"
          :image="require('@/assets/image/no_data_3.png')"
          :image-style="{ height: '186px' }"
          style="padding: 60px 0"
        >
          <template #description>
            <span style="color: #999">{{ $t("Pub_Lab_NoData") }}</span>
          </template>
        </a-empty>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import ls from "@/utils/local-storage";
import { dateFormat } from "@/utils/tools";
import { getLikeList } from "@/api/user.js";
import ListFilter from "@/components/new/filter/ListFilter.vue";
const renderVNode = (_, { attrs: { vnode } }) => vnode;
export default {
  components: {
    ListFilter,
    renderVNode,
  },
  setup() {
    const { t: $t } = useI18n();
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      typesOptions: [],
      dataList: [],
      currentPage: 1,
      pageTotal: 1,
      name: "",
      types: [],
      companyInfo: ls.get("companyInfo") || {},
      listLoading: true,
      coverImg: require("@/assets/image/coverBg.png"),
    });

    if (state.companyInfo.menu.includes("course")) {
      state.typesOptions.push({ id: 2, name: $t("CM_Curriculum") });
      state.types = [2];
      // 课程
    }
    if (state.companyInfo.menu.includes("knowledge")) {
      state.typesOptions.push({ id: 1, name: $t("Pub_Tab_Knowledge") });
      if (!state.types.length) state.types = [1];
      // 知识
    }
    if (state.companyInfo.menu.includes("news")) {
      state.typesOptions.push({ id: 10, name: $t("Lab_News_News") });
      if (!state.types.length) state.types = [10];
      // 新闻
    }

    // 重新加载list
    const reloadList = () => {
      state.dataList = [];
      state.currentPage = 1;
      getList();
    };

    const pageChange = (n) => {
      state.currentPage = n;
      getList(true);
    };

    const getList = (scroll = false) => {
      state.listLoading = true;
      getLikeList({
        page: state.currentPage,
        pageSize: 15,
        key: state.name,
        order: 1,
        types: state.types,
      }).then((res) => {
        state.listLoading = false;
        state.pageTotal = res.data.totals;
        state.dataList = res.data.list || [];
        if (scroll) {
          setTimeout(() => {
            document.getElementById("pageTop").scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }, 0);
        }
      });
    };

    const jump = (item) => {
      let newWindow = store.getters.companyInfo.useNewWindow == 1;
      if (item.resourceType == 1) {
        if (newWindow) {
          window.open(`/doc/detail?ddtab=true&knowledgeId=${item.resourceId}`);
        } else {
          router.push({
            path: "/doc/detail",
            query: {
              knowledgeId: item.resourceId,
            },
          });
        }
      } else if (item.resourceType == 10) {
        if (newWindow) {
          window.open(`/news/detail?ddtab=true&id=${item.resourceId}`);
        } else {
          router.push({
            path: "/news/detail",
            query: {
              id: item.resourceId,
            },
          });
        }
      } else if (item.resourceType == 2) {
        if (newWindow) {
          window.open(`/course/detail?ddtab=true&id=${item.resourceId}`);
        } else {
          router.push({
            path: "/course/detail",
            query: {
              id: item.resourceId,
            },
          });
        }
      }
    };

    const switchTypes = (arr) => {
      state.types = [arr[0]];
      reloadList();
    };

    const onSearch = () => {
      state.currentPage = 1;
      reloadList();
    };

    getList();

    return {
      dateFormat,
      ...toRefs(state),
      pageChange,
      jump,
      switchTypes,
      onSearch,
    };
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
@import "./card.less";
.component-wrap {
  .content-wrap {
    padding-top: 24px;
  }
}
</style>
